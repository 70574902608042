import React, { useState, useEffect, useCallback } from "react";
import { auth } from "../../../components/firebase.js";
import { Button } from "@mui/material";
import { fields } from "./formFields.js";
import { handlePostalCodeChange, submitValidate } from "./formFunctions.js";
import {
  BabySeatCheckbox,
  DefaultInput,
  SeatInput,
  TotalPrice,
} from "./formComponents.js";

export const OrderForm = (props) => {
  const [formValues, setFormValues] = useState(props.order || {});
  const [formErrors, setFormErrors] = useState({});
  const { setOrder } = props;

  const handleChange = useCallback(
    (e) => {
      const { name, type, value, checked } = e.target;
      const newValue = type === "checkbox" ? checked : value;
      const newFormValues = { ...formValues, [name]: newValue };
      setFormValues(newFormValues);
      setOrder(newFormValues);

      if (name === "postalCode") {
        handlePostalCodeChange(value, setFormValues);
      }
    },
    [formValues, setOrder]
  );

  const handleCheckout = (e) => {
    e.preventDefault();
    let errors = submitValidate(formValues, fields);

    let generalSeat = formValues.generalSeat || 0;
    let studentSeat = formValues.studentSeat || 0;
    if (generalSeat + studentSeat < 1) {
      errors.totalTickets = "チケットは1枚以上必要です";
    }

    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      props.handleCheckout();
    }
  };

  useEffect(() => {
    setFormValues(props.order || {});
  }, [props.order]);

  useEffect(() => {
    setFormErrors({});
  }, [formValues]);

  useEffect(() => {
    let generalSeat = formValues.generalSeat || 0;
    let studentSeat = formValues.studentSeat || 0;
    if (generalSeat + studentSeat < 1) {
      setFormErrors({ totalPrice: "チケットは1枚以上必要です" });
    }
    const totalPrice = generalSeat * 5000 + studentSeat * 2000;
    formValues.totalPrice = totalPrice;
    setFormValues(formValues);
    setOrder(formValues);
  }, [formValues, setOrder]);

  return (
    <div>
      <h1>申し込みフォーム</h1>
      <form onSubmit={handleCheckout}>
        {fields.map((field) => {
          if (field.name.endsWith("Seat")) {
            return (
              <SeatInput
                key={field.name}
                field={field}
                handleChange={handleChange}
                formValues={formValues}
              />
            );
          } else if (field.type === "checkbox") {
            return (
              <BabySeatCheckbox
                key={field.name}
                field={field}
                handleChange={handleChange}
                formValues={formValues}
              />
            );
          } else {
            return (
              <DefaultInput
                key={field.name}
                field={field}
                handleChange={handleChange}
                formValues={formValues}
                formErrors={formErrors}
              />
            );
          }
        })}
        <TotalPrice
          generalSeat={formValues.generalSeat}
          studentSeat={formValues.studentSeat}
        />
        <p className="errorMsg">{formErrors.totalTickets}</p>
        <p className="errorMsg">
          ベビーカーは受付でお預かりします。
          <br />
          座席番号での指定はできません。
          <br />
          ご希望に添えない場合がございます。
          <br />
          ご了承ください。
        </p>
        <p>
          <Button variant="contained" type="submit">
            確認に進む
          </Button>
        </p>
        <p>
          <Button variant="contained" onClick={() => auth.signOut()}>
            サインアウト
          </Button>
        </p>
      </form>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};
