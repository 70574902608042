import { Checkbox, FormControlLabel, Input, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

export const TotalPrice = ({ generalSeat, studentSeat }) => {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let gSeat = generalSeat || 0;
    let sSeat = studentSeat || 0;
    const totalPrice = gSeat * 5000 + sSeat * 2000;
    setTotal(totalPrice);
  }, [generalSeat, studentSeat]);

  return (
    <div className="formField">
      <label>合計金額　</label>
      {total.toLocaleString()}円
    </div>
  );
};

export const SeatInput = ({ field, handleChange, formValues }) => {
  return (
    <div className="formField">
      <label htmlFor={field.name}>{field.label}</label>
      <Input
        id={field.name}
        name={field.name}
        type={field.type}
        className="seatInput"
        placeholder="1"
        onChange={handleChange}
        value={formValues[field.name] || ""}
        inputProps={{ min: 0, style: { textAlign: "center" } }}
      />
      <span>枚</span>
    </div>
  );
};

export const BabySeatCheckbox = ({ field, handleChange, formValues }) => {
  return (
    <div className="formField">
      <FormControlLabel
        control={
          <Checkbox
            id={field.name}
            name={field.name}
            type={field.type}
            checked={formValues[field.name] || false}
            onChange={handleChange}
          />
        }
        label={
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            {field.label}
          </Typography>
        }
      />
    </div>
  );
};

export const DefaultInput = ({
  field,
  handleChange,
  formValues,
  formErrors,
}) => {
  return (
    <div className="formField">
      <label className="defaultLabel" htmlFor={field.name}>
        {field.label}
      </label>
      <br />
      <Input
        id={field.name}
        name={field.name}
        type={field.type}
        placeholder={field.label}
        onChange={handleChange}
        value={formValues[field.name] || ""}
        disabled={field.disabled || false}
        multiline={field.name === "houseAddress"}
        rowsMax={field.name === "houseAddress" ? 4 : 1}
      />
      {formErrors[field.name] && (
        <p className="errorMsg">{formErrors[field.name]}</p>
      )}
    </div>
  );
};
