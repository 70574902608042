import { Button, Input } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, googleProvider } from "../../components/firebase.js";
import { StageInfo } from "../order";
import { useEmailLinkAuth, useFetchOrCreateOrder } from "./authHooks.js";
import {
  handleEmailChange,
  handleProviderLogin,
  handleEmailLogin,
} from "./authFunctions.js";
import { useNavigate } from "react-router-dom";

export const Auth = ({ setEmail, email }) => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEmailLinkAuth(auth, setEmail);
  useFetchOrCreateOrder(user, loading, db);

  return (
    <div>
      <h2>チケット申し込みフォーム</h2>
      <p>アクセス頂きありがとうございます。</p>
      <br />
      <StageInfo />
      <br />
      <h1>お申し込み</h1>
      <form onSubmit={handleEmailLogin(auth, email, navigate)}>
        <p>
          メールアドレスでログインされる方は
          <br />
          メールアドレスを入力の上
          <br />
          届いたメールからログインをしてください。
        </p>
        <Input
          type="email"
          value={email}
          onChange={handleEmailChange(setEmail)}
          placeholder="Email"
        />
        <p>
          <Button type="submit" variant="contained">
            メールでログイン
          </Button>
        </p>
      </form>
      <br />
      <p>
        Googleアカウントでログインをされる方は
        <br />
        こちらをタップして
        <br />
        入力フォームにお進みください。
      </p>
      <Button
        onClick={handleProviderLogin(auth, googleProvider)}
        variant="contained"
      >
        Googleでログイン
      </Button>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};
