export const fields = [
  {
    label: "メールアドレス",
    name: "mailAddress",
    type: "email",
    disabled: true,
    validate: (value) => {
      if (!value) return "メールアドレスを入力してください";
      const regex =
        /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
      if (!regex.test(value)) return "正しいメールアドレスを入力してください";
    },
  },
  {
    label: "氏名",
    name: "userName",
    type: "text",
    validate: (value) => {
      if (!value) return "氏名を入力してください";
    },
  },
  {
    label: "郵便番号",
    name: "postalCode",
    type: "text",
    validate: (value) => {
      if (!value) return "郵便番号を入力してください";
    },
  },
  {
    label: "住所",
    name: "houseAddress",
    type: "text",
    validate: (value) => {
      if (!value) return "住所を入力してください";
    },
  },
  {
    label: "電話番号",
    name: "phoneNumber",
    type: "text",
    validate: (value) => {
      if (!value) return "電話番号を入力してください";
    },
  },
  {
    label: "一般指定席 5,000円",
    name: "generalSeat",
    type: "number",
    validate: (value) => {
      if (value < 0) return "0以上の枚数を入力してください";
    },
  },
  {
    label: "学生席(6歳以上) 2,000円",
    name: "studentSeat",
    type: "number",
    validate: (value) => {
      if (value < 0) return "0以上の枚数を入力してください";
    },
  },
  {
    label: "抱っこ席希望",
    name: "isBabySeatFlag",
    type: "checkbox",
  },
  {
    label: "備考欄",
    name: "remarks",
    type: "text",
  },
];
