import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { OrderForm, OrderInfo, StageInfo } from "../features/order";
import { db } from "../components/firebase.js";

export const Order = ({ user }) => {
  const [order, setOrder] = useState(null);
  const navigate = useNavigate();

  const handleCheckout = async () => {
    try {
      const docRef = doc(db, "orders", user.uid);
      await setDoc(docRef, {
        ...order,
        status: 2,
        updatedAt: serverTimestamp(),
      });
      const docSnap = await getDoc(docRef);
      setOrder(docSnap.data());
      navigate("/event/202310/checkout");
    } catch (error) {
      console.error("Error checkout: ", error);
    }
  };

  useEffect(() => {
    if (user) {
      const fetchOrCreateOrder = async () => {
        const docRef = doc(db, "orders", user.uid);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          await setDoc(docRef, {
            mailAddress: user.email,
            createdAt: serverTimestamp(),
            status: 1,
          });
          const newDocSnap = await getDoc(docRef);
          setOrder(newDocSnap.data());
        } else {
          setOrder(docSnap.data());
        }
      };
      fetchOrCreateOrder();
    }
  }, [user]);

  return (
    <div>
      {order && [1, 2].includes(order.status) ? (
        <div>
          <StageInfo />
          <OrderForm
            order={order}
            setOrder={setOrder}
            mailAddress={user.email}
            handleCheckout={handleCheckout}
          />
        </div>
      ) : order && ![1, 2].includes(order.status) ? (
        <div>
          <StageInfo />
          <OrderInfo order={order} />
        </div>
      ) : (
        "Loading..."
      )}
    </div>
  );
};
