import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const Completed = () => {
  const navigate = useNavigate();

  return (
    <div>
      <h1>お申し込み完了</h1>
      <p>お申し込みが完了しました。</p>
      <p>
        後日、振込依頼メールをお送りします。
        <br />
        ご確認ください。
      </p>
      <Button variant="contained" onClick={() => navigate("/event/202310")}>
        お申し込みトップ画面に戻る
      </Button>
    </div>
  );
};
