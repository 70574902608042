import { Button } from "@mui/material";
import { auth, db } from "../../components/firebase.js";
import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

export const OrderInfo = () => {
  const [user, loading] = useAuthState(auth);
  const [order, setOrder] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && user) {
      const fetchOrder = async () => {
        const docRef = doc(db, "orders", user.uid);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          navigate("/event/202310");
        } else {
          setOrder(docSnap.data());
        }
      };
      fetchOrder();
    }
  }, [order, user, loading, navigate]);

  return (
    <div>
      {order ? (
        <div>
          <h1>
            お申込みいただいた
            <br />
            チケットの内容
          </h1>
          <p>メールアドレス：{order.mailAddress}</p>
          <p>氏名：{order.userName}</p>
          <p>郵便番号：{order.postalCode}</p>
          <p>住所：{order.houseAddress}</p>
          <p>電話番号：{order.phoneNumber}</p>
          <p>一般指定席：{order.generalSeat || 0}枚</p>
          <p>学生席：{order.studentSeat || 0}枚</p>
          <p>抱っこ席：{order.isBabySeatFlag ? "希望する" : "希望しない"}</p>
          <p>備考欄：{order.remarks}</p>
          <p>チケットの合計金額：{order.totalPrice.toLocaleString()}円</p>
          <Button onClick={() => auth.signOut()}>サインアウト</Button>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      ) : (
        "Loading..."
      )}
    </div>
  );
};
