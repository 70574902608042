import { useEffect } from "react";
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";

export const useEmailLinkAuth = (auth, setEmail) => {
  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let storedEmail = window.localStorage.getItem("emailForSignIn");
      if (!storedEmail) {
        setEmail("prompt");
      } else {
        signInWithEmailLink(auth, storedEmail, window.location.href)
          .then(() => {
            setEmail(storedEmail);
            window.localStorage.removeItem("emailForSignIn");
          })
          .catch((error) => {
            console.error("Error signing in: ", error);
          });
      }
    }
  }, [auth, setEmail]);
};

export const useFetchOrCreateOrder = (user, loading, db) => {
  useEffect(() => {
    if (!loading && user) {
      const fetchOrCreateOrder = async () => {
        const docRef = doc(db, "orders", user.uid);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          await setDoc(docRef, {
            mailAddress: user.email,
            createdAt: serverTimestamp(),
            status: 1,
          });
        }
      };
      fetchOrCreateOrder();
    }
  }, [user, loading, db]);
};
