import { Route, Routes } from "react-router-dom";
import "./App.css";
import {
  Notfound,
  Signout,
  Completed,
  OpenMail,
  Coming,
  Current,
} from "./routes";
import { OrderCheckout } from "./features/order";

function LayoutWithHeader({ children }) {
  return (
    <div>
      <h1>花能舎之會</h1>
      {children}
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Coming />} />
        <Route
          path="/event/202310"
          element={
            <LayoutWithHeader>
              <Current />
            </LayoutWithHeader>
          }
        />
        <Route
          path="/event/202310/checkout"
          element={
            <LayoutWithHeader>
              <OrderCheckout />
            </LayoutWithHeader>
          }
        />
        <Route
          path="/event/202310/completed"
          element={
            <LayoutWithHeader>
              <Completed />
            </LayoutWithHeader>
          }
        />
        <Route
          path="/openmail"
          element={
            <LayoutWithHeader>
              <OpenMail />
            </LayoutWithHeader>
          }
        />
        <Route
          path="/signout"
          element={
            <LayoutWithHeader>
              <Signout />
            </LayoutWithHeader>
          }
        />
        <Route
          path="*"
          element={
            <LayoutWithHeader>
              <Notfound />
            </LayoutWithHeader>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
