import React, { useRef, useCallback } from "react";

export const ImageDialog = ({ src, alt = "" }) => {
  const ref = useRef(null);

  /**
   * ダイアローグを開く処理
   */
  const handleOpenDialog = useCallback(() => {
    if (ref.current) ref.current.showModal();
  }, [ref]);

  /**
   * ダイアローグを閉じる処理
   */
  const handleCloseDialog = useCallback(() => {
    if (ref.current) ref.current.close();
  }, [ref]);

  /**
   * ダイアローグ内のクリックイベントハンドラーに渡す処理
   */
  const handleClickInDialog = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <React.Fragment>
      <div
        role="button"
        onClick={handleOpenDialog}
        onKeyDown={handleOpenDialog}
        className="image-box"
        tabIndex={0}
      >
        <img src={src} alt={alt} width="60%" />
      </div>
      <dialog ref={ref} className="image-dialog" onClick={handleCloseDialog}>
        <div onClick={handleClickInDialog} className="contents">
          <img src={src} alt={alt} width="100%" />
        </div>
      </dialog>
    </React.Fragment>
  );
};
