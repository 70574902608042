import { useState, useEffect } from "react";
import { auth, db } from "../../components/firebase.js";
import { Button } from "@mui/material";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

export const OrderCheckout = () => {
  const [user, loading] = useAuthState(auth);
  const [order, setOrder] = useState(null);
  const navigate = useNavigate();

  const sendOrder = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(db, "orders", user.uid);
      await setDoc(docRef, {
        ...order,
        status: 3,
        updatedAt: serverTimestamp(),
      });
      navigate("/event/202310/completed");
    } catch (error) {
      console.error("Error completed: ", error);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  useEffect(() => {
    if (!loading && user) {
      const fetchOrder = async () => {
        const docRef = doc(db, "orders", user.uid);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          navigate("/event/202310");
        } else {
          setOrder(docSnap.data());
        }
      };
      fetchOrder();
    }
  }, [order, user, loading, navigate]);

  return (
    <div>
      {order ? (
        <div>
          <h1>
            以下の内容で
            <br />
            お申し込みします
          </h1>
          <p>メールアドレス：{order.mailAddress}</p>
          <p>氏名：{order.userName}</p>
          <p>郵便番号：{order.postalCode}</p>
          <p>住所：{order.houseAddress}</p>
          <p>電話番号：{order.phoneNumber}</p>
          <p>一般指定席：{order.generalSeat || 0}枚</p>
          <p>学生席：{order.studentSeat || 0}枚</p>
          <p>抱っこ席：{order.isBabySeatFlag ? "希望する" : "希望しない"}</p>
          <p>備考欄：{order.remarks}</p>
          <p>チケットの合計金額：{order.totalPrice.toLocaleString()}円</p>
          <Button variant="contained" onClick={handleBack}>
            編集に戻る
          </Button>
          　
          <Button variant="contained" onClick={sendOrder}>
            申し込む
          </Button>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      ) : (
        "Loading..."
      )}
    </div>
  );
};
