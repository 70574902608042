export const submitValidate = (values, fields) => {
  const errors = {};

  fields.forEach((field) => {
    if (field.validate) {
      const errorMessage = field.validate(values[field.name]);
      if (errorMessage) {
        errors[field.name] = errorMessage;
      }
    }
  });

  return errors;
};

export const handlePostalCodeChange = async (value, setFormValues) => {
  let postalCode = value;
  const postalCodeRegex = /^\d{3}-?\d{4}$/;
  if (!postalCodeRegex.test(postalCode)) return;
  postalCode = postalCode.replace("-", "");
  const res = await fetch(
    `https://apis.postcode-jp.com/api/v5/postcodes/${postalCode}?apikey=${process.env.REACT_APP_POSTCODE_API_KEY}`
  );
  if (!res.ok) return;
  const data = await res.json();
  if (res.status !== 200 || !data[0].allAddress) return;
  setFormValues((prev) => ({ ...prev, houseAddress: data[0].allAddress }));
};
