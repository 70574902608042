import { ImageDialog } from "../../components/ImageDialog";

export const StageInfo = () => {
  return (
    <div>
      <p>日時：2023年 10月 22日（日）</p>
      <p>会場：セルリアンタワー能楽堂</p>
      <p>13時開演／12時開場</p>
      <ImageDialog src="/dm1.jpg" alt="dm1" />
      <ImageDialog src="/dm2.jpg" alt="dm2" />
    </div>
  );
};
