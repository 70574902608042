import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Auth } from "../features/auth/Auth.js";
import { Order } from "./Order.js";
import { auth } from "../components/firebase.js";

export const Current = () => {
  const [user] = useAuthState(auth);
  const [email, setEmail] = useState("");

  return (
    <div>
      {!user ? (
        <Auth setEmail={setEmail} email={email} />
      ) : (
        <Order user={user} />
      )}
    </div>
  );
};
