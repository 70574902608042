import React from "react";

export const OpenMail = () => {
  return (
    <div>
      <h1>メールを送信しました</h1>
      <p>
        届いたメールのリンクをクリックし
        <br />
        ログインしてください。
      </p>
      <p>
        iPhoneの方はこちら
        <br />
        <a href="message:">受信トレイを開く</a>
      </p>
      <p>
        Androidの方はこちら
        <br />
        <a href="android-app://com.google.android.gm">受信トレイを開く</a>
      </p>
      <br />
      <br />
      <p>
        メールが届かない場合は
        <br />
        別のログイン方法でお試しください。
      </p>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};
