import React, { useEffect } from "react";
import BlackStage from "../images/black-noh-stage.jpeg";

export const Coming = () => {
  useEffect(() => {
    // Component did mount
    document.body.style.overflow = "hidden";

    return () => {
      // Component did unmount
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${BlackStage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        display: "flex",
        justifyContent: "flex-start", // 左寄せ
        alignItems: "flex-end", // 下寄せ
        fontSize: "0.4rem",
        color: "black", // 文字色を黒に
        fontWeight: "bold",
        paddingLeft: "8%", // 左からの位置
        paddingBottom: "10%", // 下からの位置
      }}
    >
      <h1 style={{ marginBottom: "31%" }}>coming soon...</h1>
    </div>
  );
};
