import { sendSignInLinkToEmail, signInWithPopup } from "firebase/auth";

export const handleEmailChange = (setEmail) => (event) => {
  setEmail(event.target.value);
  window.localStorage.setItem("emailForSignIn", event.target.value);
};

export const handleProviderLogin = (auth, provider) => async () => {
  try {
    await signInWithPopup(auth, provider);
  } catch (error) {
    console.error("Error signing in: ", error);
  }
};

export const handleEmailLogin = (auth, email, navigate) => async (event) => {
  event.preventDefault();

  try {
    const actionCodeSettings = {
      url: `${process.env.REACT_APP_PUBLIC_URL}/event/202310`,
      handleCodeInApp: true,
    };
    await sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem("emailForSignIn", email);
      })
      .catch((error) => {
        console.error("Error signing in: ", error);
      });
    navigate("/openmail");
  } catch (error) {
    console.error("Error signing in: ", error);
  }
};
